import {
  PageContent,
  PageTitle,
  PageTitle2,
  PageTitle3,
} from "../../components/Page";
import { CampingRoute } from "../../router/CampingRoute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faTree,
  faCalendarDays,
  faCampground,
  faWater,
  faCoins,
  faPersonSwimming,
  faRestroom,
  faCreditCard,
  faMoneyBill1,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";
import { EVENTS } from "./events";
import { EventDate } from "../../components/EventDate";
import { BREAKPOINTS } from "../../breakpoints";
import { Coordinates } from "../../components/Coordinates";
import { Fragment } from "react/jsx-runtime";

const Flex = styled.div`
  display: flex;
`;

const ColumnFlex = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.tablet}px) {
    flex-direction: column;
  }
`;

const FlexGrow = styled.div`
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
`;

const ColumnFlexGrow = styled.div`
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    width: auto;
    max-width: 50%;
    padding-right: 25px;
  }
`;

const EventContent = styled.div`
  padding-left: 15px;
`;

const EventDescription = styled.p`
  position: relative;
  padding: 0;
  margin: 0;
  & + h4 {
    margin-top: 15px;
  }
`;

const EventSubItemTitle = styled.h4`
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 14px;
`;

const EventDetailsList = styled.table`
  margin-top: 10px;
  margin-bottom: 10px;
  border-spacing: 0;
`;

const EventDetailsListTitle = styled.td`
  font-size: 11px;
  font-weight: bold;
  width: 95px;
  text-transform: uppercase;
  line-height: 20px;
`;

const EventDetailsListValue = styled.td`
  flex-grow: 1;
  font-size: 12px;
`;

const EventImages = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
  background-color: black;
  border-radius: 20px;
  box-shadow:
    2px 5px 16px 0px #0b325e,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  overflow: hidden;
`;

const EventImage = styled.img`
  vertical-align: bottom;
  height: 300px;
`;

interface EventDetailsProps {
  hours: string;
  signer?: string;
  cost?: string;
  admission?: string;
  images?: string[];
}

function EventDetails({
  hours,
  cost,
  admission,
  signer,
  images,
}: EventDetailsProps) {
  return (
    <>
      <EventDetailsList>
        <tbody>
          {signer !== undefined && (
            <tr>
              <EventDetailsListTitle>Chansonnier :</EventDetailsListTitle>
              <EventDetailsListValue>{signer}</EventDetailsListValue>
            </tr>
          )}
          {cost !== undefined && (
            <tr>
              <EventDetailsListTitle>Coût :</EventDetailsListTitle>
              <EventDetailsListValue>{cost}</EventDetailsListValue>
            </tr>
          )}
          <tr>
            <EventDetailsListTitle>Heures :</EventDetailsListTitle>
            <EventDetailsListValue>{hours}</EventDetailsListValue>
          </tr>
          {admission !== undefined && (
            <tr>
              <EventDetailsListTitle>Admission :</EventDetailsListTitle>
              <EventDetailsListValue>{admission}</EventDetailsListValue>
            </tr>
          )}
        </tbody>
      </EventDetailsList>
      {images && (
        <EventImages>
          {images.map((image) => (
            <a href={image} key={image} target="_blank" rel="noreferrer">
              <EventImage src={image} />
            </a>
          ))}
        </EventImages>
      )}
    </>
  );
}

function Informations() {
  return (
    <PageContent>
      <PageTitle>Informations</PageTitle>
      <ColumnFlex>
        <ColumnFlexGrow>
          <PageTitle2>
            <FontAwesomeIcon icon={faLocationDot} /> Coordonnées
          </PageTitle2>
          <Coordinates />
        </ColumnFlexGrow>
        <ColumnFlexGrow>
          <PageTitle2>
            <FontAwesomeIcon icon={faTree} /> Saison 2025
          </PageTitle2>
          <p>16 mai au 21 septembre</p>
        </ColumnFlexGrow>
      </ColumnFlex>
      <PageTitle2>
        <FontAwesomeIcon icon={faCalendarDays} /> Évènements
      </PageTitle2>
      {EVENTS.map((event) => (
        <Fragment key={event.title}>
          <PageTitle3>{event.title}</PageTitle3>
          <Flex>
            <div>
              <EventDate date={event.date} />
            </div>
            <FlexGrow>
              <EventContent>
                {event.description && (
                  <EventDescription>{event.description}</EventDescription>
                )}
                {event.hours && (
                  <EventDetails
                    cost={event.cost}
                    hours={event.hours}
                    signer={event.signer}
                    admission={event.admission}
                    images={event.images}
                  />
                )}
                {event.subitems &&
                  event.subitems.map((subItem) => (
                    <Fragment key={subItem.title}>
                      <EventSubItemTitle>{subItem.title}</EventSubItemTitle>
                      <EventDetails
                        cost={subItem.cost}
                        hours={subItem.hours}
                        admission={subItem.admission}
                        images={subItem.images}
                      />
                    </Fragment>
                  ))}
              </EventContent>
            </FlexGrow>
          </Flex>
        </Fragment>
      ))}
      <ColumnFlex>
        <ColumnFlexGrow>
          <PageTitle2>
            <FontAwesomeIcon icon={faCampground} /> Particularités du camping
          </PageTitle2>
          <p>50 emplacements</p>
          <p>Petit dépanneur (pain, lait, beurre, glace, bois, ...)</p>

          <PageTitle2>
            <FontAwesomeIcon icon={faPersonSwimming} /> Activités
          </PageTitle2>
          <p>
            Piscine extérieure, pédalo, kayak, chaloupe, pêche, terrain de jeux,
            trampoline
          </p>

          <PageTitle2>
            <FontAwesomeIcon icon={faRestroom} /> Services
          </PageTitle2>
          <p>14 terrains ayant 3 services sont disponibles pour passagers</p>
          <p>
            6 terrains pour les tentes ayant 2 services sont disponibles [#45 à
            #50]
          </p>
          <p>
            Douches, électricité – 30 ampères, glace/bois laveuse/sécheuse,
            salle communautaire station de vidange, restaurant, petit dépanneur
          </p>
          <p>
            <FontAwesomeIcon color="gray" icon={faWifi} /> Internet sans fil
          </p>
        </ColumnFlexGrow>
        <ColumnFlexGrow>
          <PageTitle2>
            <FontAwesomeIcon icon={faWater} /> Services de location
          </PageTitle2>
          <p>Location d’embarcations</p>
          <ul>
            <li>Pédalos (1 disponibles)</li>
            <li>Kayak (2 disponibles)</li>
            <li>Chaloupes (2 disponibles)</li>
          </ul>
          <p>Ceintures de sécurité incluses</p>
          <PageTitle2>
            <FontAwesomeIcon icon={faCoins} /> Modes de paiement
          </PageTitle2>
          <ul>
            <li>
              <FontAwesomeIcon color="gray" icon={faMoneyBill1} /> Comptant
            </li>
            <li>
              <FontAwesomeIcon color="gray" icon={faCreditCard} /> Interac
            </li>
            <li>
              <FontAwesomeIcon color="gray" icon={faCcVisa} /> Visa
            </li>
            <li>
              <FontAwesomeIcon color="gray" icon={faCcMastercard} /> Mastercard
            </li>
          </ul>
        </ColumnFlexGrow>
      </ColumnFlex>
    </PageContent>
  );
}

export const InformationsRoute: CampingRoute = {
  title: "Informations",
  path: "/informations",
  element: <Informations />,
};
