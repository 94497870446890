import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import { CampingRoute } from "../router/CampingRoute";
import { Burger } from "./Burger";
import { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import type { LinkProps } from "react-router-dom";
import { BREAKPOINTS } from "../breakpoints";
import useBreakpoint from "use-breakpoint";

interface IsFloatingProps {
  $isFloating: boolean;
}

interface IsOpenProps {
  isOpen: boolean;
}

const BurgerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const Modal = styled.div<IsOpenProps>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  transform: ${({ isOpen }: IsOpenProps) =>
    isOpen ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
`;

const NavContainerDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(1, 139, 22, 0.8);
`;

const Nav = styled.nav<IsFloatingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  ${({ $isFloating }) =>
    $isFloating
      ? `
    flex-direction: column;
    height: 100%;
    align-content: center;`
      : ""};
`;

interface NavLinkProps {
  $isFloating: boolean;
  $isActive: boolean;
}

const NavLink = styled(Link)<NavLinkProps>`
  padding: 15px 1.2vw;
  color: ${({ $isFloating }) => ($isFloating ? "black" : "white")};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  ${({ $isFloating }) => ($isFloating ? "text-align: center;" : "")}
  ${({ $isActive }) =>
    $isActive
      ? `
    font-weight: bold; 
    font-size: 1.1em;
    `
      : ""}
`;

function CustomLink({ children, to, ...props }: LinkProps & IsFloatingProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink to={to} $isActive={Boolean(match)} {...props}>
      {children}
    </NavLink>
  );
}

interface NavContainerProps {
  $isFloating: boolean;
  isOpen: boolean;
}

function NavContainer({
  $isFloating,
  isOpen,
  children,
}: PropsWithChildren<NavContainerProps>) {
  if ($isFloating) {
    return ReactDOM.createPortal(
      <Modal isOpen={isOpen}>{children}</Modal>,
      document.body
    );
  }

  return <>{children}</>;
}

export interface MenuProps {
  pages: CampingRoute[];
}

export function Menu({ pages }: MenuProps) {
  const [isOpen, setOpen] = useState(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [isFloating, setFloating] = useState(breakpoint === "mobile");
  const { pathname } = useLocation();

  useEffect(() => {
    setFloating(breakpoint === "mobile");
  }, [breakpoint]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <NavContainerDiv>
      {isFloating && (
        <BurgerContainer>
          <Burger
            isOpen={false}
            onClick={() => {
              setOpen(true);
            }}
          />
        </BurgerContainer>
      )}

      <NavContainer isOpen={isOpen} $isFloating={isFloating}>
        {isFloating && (
          <BurgerContainer>
            <Burger
              isOpen={true}
              onClick={() => {
                setOpen(false);
              }}
            />
          </BurgerContainer>
        )}
        <Nav $isFloating={isFloating}>
          {pages.map((page) => (
            <CustomLink key={page.path} to={page.path} $isFloating={isFloating}>
              {page.title}
            </CustomLink>
          ))}
        </Nav>
      </NavContainer>
    </NavContainerDiv>
  );
}
