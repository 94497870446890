import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomeRoute } from "./pages/Home";
import { InformationsRoute } from "./pages/Informations";
import { MapRoute } from "./pages/Map";
import { PhotosRoute } from "./pages/Photos";
import { PricesRoute } from "./pages/Prices";
import { WhereAreWeRoute } from "./pages/WhereAreWe";
import { CampingThemeProvider } from "./theme";
import { BrowserMode } from "./theme/BrowserModeProvider";
import { Header } from "./header";
import styled, { createGlobalStyle } from "styled-components";
import { Footer } from "./footer/Footer";
import { PrintMap } from "./pages/PrintMap";

const pages = [
  HomeRoute,
  InformationsRoute,
  MapRoute,
  PricesRoute,
  WhereAreWeRoute,
  PhotosRoute,
];

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #027826;
  }
`;

export function NormalPages() {
  return (
    <>
      <GlobalStyle />
      <Header pages={pages} />

      <Page>
        <Routes>
          {pages.map((page) => (
            <Route key={page.path} path={page.path} element={page.element} />
          ))}
        </Routes>
      </Page>

      <Footer />
    </>
  );
}

export function App() {
  return (
    <CampingThemeProvider lock={BrowserMode.Light}>
      <BrowserRouter>
        <Routes>
          <Route path="map">
            <Route path="print" element={<PrintMap />} />
          </Route>
          <Route path="*" element={<NormalPages />} />
        </Routes>
      </BrowserRouter>
    </CampingThemeProvider>
  );
}
