import styled from "styled-components";

const BurgerContainer = styled.button<{
  $isOpen: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: content-box;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ $isOpen }) => ($isOpen ? "#0D0C1D" : "#EFFFFA")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      opacity: ${({ $isOpen }) => ($isOpen ? "0" : "1")};
      transform: ${({ $isOpen }) =>
        $isOpen ? "translateX(20px)" : "translateX(0)"};
    }

    &:nth-child(3) {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export interface BurgerProps {
  isOpen: boolean;
  onClick: () => void;
}

export const Burger = ({ isOpen, onClick }: BurgerProps) => {
  return (
    <BurgerContainer $isOpen={+isOpen} onClick={onClick}>
      <div />
      <div />
      <div />
    </BurgerContainer>
  );
};
