export interface LotPeople {
  id: string;
  name: string;
  state: LotState;
  people?: string[];
}

export enum LotState {
  SeasonRented,
  SeasonFree,
  PassengerFree,
  PassengerTent,
  Owner,
}

export const LOTS: Record<string, LotPeople> = {
  lot1: {
    id: "lot1",
    name: "1",
    state: LotState.SeasonRented,
    people: ["Myriam", "Michael"],
  },
  lot2: {
    id: "lot2",
    name: "2",
    state: LotState.SeasonRented,
    people: ["Renaude", "Daniel"],
  },
  lot3: {
    id: "lot3",
    name: "3",
    state: LotState.SeasonFree,
  },
  lot4: {
    id: "lot4",
    name: "4",
    state: LotState.SeasonFree,
  },
  lot5: {
    id: "lot5",
    name: "5",
    state: LotState.SeasonRented,
    people: ["Ghyslaine", "Benoît"],
  },
  lot6: {
    id: "lot6",
    name: "6",
    state: LotState.SeasonRented,
    people: ["Marie-Chantal", "Rocky"],
  },
  lot7: {
    id: "lot7",
    name: "7",
    state: LotState.SeasonRented,
    people: ["Lise", "Réjean"],
  },
  lot8: {
    id: "lot8",
    name: "8",
    state: LotState.SeasonRented,
    people: ["Alfreda", "Marcel"],
  },
  lot9: {
    id: "lot9",
    name: "9",
    state: LotState.SeasonRented,
    people: ["Aline", "Gino"],
  },
  lot10: {
    id: "lot10",
    name: "10",
    state: LotState.SeasonRented,
    people: ["Sophie", "Louis"],
  },
  lot11: {
    id: "lot11",
    name: "des Propriétaires",
    state: LotState.Owner,
    people: ["Véronique", "Jasmin"],
  },
  lot12: {
    id: "lot12",
    name: "12",
    state: LotState.SeasonRented,
    people: ["Karine"],
  },
  lot13: {
    id: "lot13",
    name: "13",
    state: LotState.SeasonFree,
  },
  lot14: {
    id: "lot14",
    name: "14",
    state: LotState.PassengerFree,
  },
  lot15: {
    id: "lot15",
    name: "15",
    state: LotState.SeasonRented,
    people: ["Yvonne", "Yannick"],
  },
  lot16: {
    id: "lot16",
    name: "16",
    state: LotState.SeasonRented,
    people: ["Lucie", "Vincent"],
  },
  lot17: {
    id: "lot17",
    name: "17",
    state: LotState.SeasonRented,
    people: ["Emma", "Jimmy"],
  },
  lot18: {
    id: "lot18",
    name: "18",
    state: LotState.SeasonRented,
    people: ["Fernand"],
  },
  lot19: {
    id: "lot19",
    name: "19",
    state: LotState.SeasonRented,
    people: ["Josée", "René"],
  },
  lot21: { id: "lot21", name: "21", state: LotState.PassengerFree },
  lot22: { id: "lot22", name: "22", state: LotState.PassengerFree },
  lot23: { id: "lot23", name: "23", state: LotState.PassengerFree },
  lot24: { id: "lot24", name: "24", state: LotState.PassengerFree },
  lot25: { id: "lot25", name: "25", state: LotState.PassengerFree },
  lot26: { id: "lot26", name: "26", state: LotState.PassengerFree },
  lot27: { id: "lot27", name: "27", state: LotState.PassengerFree },
  lot28: { id: "lot28", name: "28", state: LotState.PassengerFree },
  lot29: { id: "lot29", name: "29", state: LotState.PassengerFree },
  lot30: {
    id: "lot30",
    name: "30",
    state: LotState.PassengerFree,
  },
  lot31: {
    id: "lot31",
    name: "31",
    state: LotState.SeasonRented,
    people: ["Geneviève", "Damien"],
  },
  lot32: {
    id: "lot32",
    name: "32",
    state: LotState.SeasonRented,
    people: ["Cathy", "Martin"],
  },
  lot33: {
    id: "lot33",
    name: "33",
    state: LotState.SeasonRented,
    people: ["Johan", "Roger"],
  },
  lot34: {
    id: "lot34",
    name: "34",
    state: LotState.SeasonRented,
    people: ["Francine", "Sylvain"],
  },
  lot35: {
    id: "lot35",
    name: "35",
    state: LotState.PassengerFree,
  },
  lot37: {
    id: "lot37",
    name: "37",
    state: LotState.SeasonRented,
    people: ["Isabelle", "Dany"],
  },
  lot38: {
    id: "lot38",
    name: "38",
    state: LotState.SeasonRented,
    people: ["Isabelle", "Jérôme"],
  },
  lot39: {
    id: "lot39",
    name: "39",
    state: LotState.SeasonRented,
    people: ["Chantal", "Pierre"],
  },
  lot40: {
    id: "lot40",
    name: "40",
    state: LotState.SeasonRented,
    people: ["Marie-Claude", "Stéphane"],
  },
  lot41: {
    id: "lot41",
    name: "41",
    state: LotState.SeasonRented,
    people: ["Obélia", "Jeffrey"],
  },
  lot42: {
    id: "lot42",
    name: "42",
    state: LotState.SeasonRented,
    people: ["Marie-Lise", "Patrick"],
  },
  lot43: {
    id: "lot43",
    name: "43",
    state: LotState.SeasonRented,
    people: ["Andreanne", "Daniel"],
  },
  lot44: {
    id: "lot44",
    name: "44",
    state: LotState.SeasonRented,
    people: ["Michel"],
  },
  "lot45-50": {
    id: "lot45-50",
    name: "45 à 50",
    state: LotState.PassengerTent,
  },
  lotHost: {
    id: "lotHost",
    name: "des Propriétaires",
    state: LotState.Owner,
    people: ["Véronique", "Jasmin"],
  },
};
