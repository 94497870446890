import KarineLauzon from "./karine_lauzon.jpeg";
import Kabotineenfete from "./kabotineenfete.webp";
import KathouCantine from "./kathou_cantine.jpg";

interface Event {
  date: Date;
  title: string;
  description?: string;
  hours?: string;
  cost?: string;
  admission?: string;
  signer?: string;
  images?: string[];
  subitems?: SubEvent[];
}

interface SubEvent {
  title: string;
  hours: string;
  cost?: string;
  admission?: string;
  images?: string[];
}

export const EVENTS: Event[] = [
  {
    date: new Date("2025-05-31 12:00"),
    title: "Bingo",
    description: "Tentez votre chance...",
    hours: "à 19h00",
    cost: `5$ à l'entrée (2 cartes incluses valident pour les 10 tours) et 1$
              par cartes supplémentaires (valident 2 tours)`,
    admission: "pour tous",
    subitems: [
      {
        title: `Foodtruck sur place (Kathou cantine)`,
        hours: "Samedi de 11h à 19h et Dimanche de 11h à 14h",
        images: [KathouCantine],
      },
    ],
  },
  {
    date: new Date("2025-06-06 12:00"),
    title: "Vente de garage",
    description: "Peut-être vous trouverez la perle rare?",
    hours: "samedi toute la journée et se poursuit le 7 juin jusqu'à 15h",
    cost: `gratuit`,
    admission: "pour tous",
  },
  {
    date: new Date("2024-06-28 12:00"),
    title: "Saint-Jean",
    description: `Musique, feu de camp et des feux d'artifice seront au rendez-vous.`,
    hours: "de 20h00 à minuit",
    signer: "Karine Lauzon et Stéphane",
    images: [KarineLauzon],
    subitems: [
      {
        title: `Candy truck (slush, barbe à papa, bonbons, pop corn et breuvages)`,
        hours: "à partir de 13h00",
        images: [Kabotineenfete],
      },
    ],
  },
  {
    date: new Date("2024-07-26 12:00"),
    title: "Noël des campeurs",
    description: "Jeu gonflable sur place toute la journeé",
    subitems: [
      {
        title: `Arrivé du Père Noël, distribution des cadeaux et prix de participation de 50$ pour la décoration de sa roulotte`,
        hours: "à 14h00",
      },
      {
        title:
          "Souper de Noël version Potluck et, après, échange de cadeaux drôle et insolite d'une valeur d'environ 20$",
        hours: "à 18h00",
      },
    ],
  },
  {
    date: new Date("2024-08-30 12:00"),
    title: "Halloween des campeurs",
    subitems: [
      {
        title: "Cueillette de bonbons sur le camping",
        hours: "à 14h00",
      },
      {
        title: "DJ et animation (mettez vos déguisements pour la soirée)",
        hours: "à 20h00",
      },
    ],
  },
  {
    date: new Date("2024-09-20 12:00"),
    title: "Méchoui (Karaméchoui Pierre Veilleux)",
    cost: "0-5 ans gratuit, 6-11 ans 17$, 12 ans et plus 27$ *taxes non-inclu",
    hours: "à 18h00 (réservation obligatoire avant le 1 septembre)",
  },
];
